<!-- 会员等级 -->
<template>
    <div class="membermanagebox">
        <div class="membermanage">
            <div class="global-search-single">
                <div class="single-left">
                    <el-button type="primary" @click="handleCreateLevel"
                        v-if="hasPerm(['user.level.store'])">创建等级</el-button>
                    <el-button type="primary" @click="handleUpgrade"
                        v-if="hasPerm(['user.upgrade.description'])">会员升级说明</el-button>
                </div>
                <div class="single-right">
                    <div class="search-item">
                        <label class="search-label">等级名称：</label>
                        <el-input type="text" v-model="search.name" placeholder="请输入等级名称" />
                    </div>
                    <el-button type="primary" @click="handleSearch">搜索</el-button>
                </div>
            </div>

            <!-- <div class="addmember clearfloat">
                <a href="javascript:void(0)" @click="handleCreateLevel" v-if="hasPerm(['user.level.store'])">创建等级</a>
                <a href="javascript:void(0);" @click="handleUpgrade" v-if="hasPerm(['user.upgrade.description'])">会员升级说明</a>
                <div class="addinput clearfloat">
                    <span>等级名称：</span>
                    <div class="addinputright">
                        <el-input type="text" v-model="search.name" placeholder="请输入等级名称" clearable @clear="handleClear"
                            @keyup.enter.native="handleSearch" @input="handleInput"></el-input>
                        <i class="el-icon-search"></i>
                    </div>
                </div>
            </div> -->
            <div class="addlevel">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="level" label="等级" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="等级名称" align="center">
                    </el-table-column>
                    <el-table-column prop="discount" label="折扣" align="center">
                    </el-table-column>
                    <el-table-column prop="price" label="购买所需金额" align="center">
                    </el-table-column>
                    <!-- <el-table-column prop="address" label="升级条件" align="center"></el-table-column> -->
                    <el-table-column label="权益状态" align="center">
                        <template slot-scope="scope">
                            <span>{{ common_info['user.level_status'] && common_info['user.level_status'][scope.row.status]
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="left">
                        <template slot-scope="scope">
                            <div class="setoperate">
                                <a href="javascript:void(0);" @click="handleModify(scope.row)"
                                    v-if="hasPerm(['user.level.update'])">编辑</a>
                                <a href="javascript:void(0);" @click="handleDelete(scope.row)"
                                    v-if="hasPerm(['user.level.delete'])">删除</a>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="tablepage">
                <div class="orderlastbutright">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pageInfo.page" :page-sizes="[10, 20, 30, 50]" :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
            <!-- <div class="batchtable">
                <div class="batchtablecon">
                    <table>
                        <thead>
                            <tr>
                                <th class="tableleft">等级</th>
                                <th>等级名称</th>
                                <th>折扣</th>
                                <th>购买所需金额</th>
                                <th>升级条件</th>
                                <th>状态</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <colgroup>
                            <col style="width:10%">
                            <col style="width:16%">
                            <col style="width:14%">
                            <col style="width:16%">
                            <col style="width:16%">
                            <col style="width:16%">
                            <col style="width:14%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td class="tableleft">1</td>
                                <td>金牌会员</td>
                                <td>9.0</td>
                                <td>99</td>
                                <td>99999.00</td>
                                <td>
                                    <el-switch v-model="vipstatus" active-color="#000c9a" inactive-color="#bbb"></el-switch>
                                </td>
                                <td>
                                    <div class="setoperate">
                                        <a href="javascript:void(0);">编辑</a>
                                        <a href="javascript:void(0);">删除</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="tablepage d-flex justify-content-center">
                    <div class="orderlastbutright">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage1" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                            layout="total, sizes, prev, pager, next, jumper" :total="400">
                        </el-pagination>
                    </div>
                </div>
                    </div> -->
        </div>
        <!-- 会员升级说明弹层 -->
        <el-dialog title="会员升级说明" class="upgradetc" :visible.sync="upgradetype">
            <el-form :model="upgradetcObj" class="upgradetcbox" ref="pgradetcForm">
                <el-form-item :rules="{ required: true, message: '请输入会员升级说明!', trigger: 'blur' }" prop="describe">
                    <div>
                        <el-input type="textarea" placeholder="请输入会员升级说明" v-model="upgradetcObj.describe"></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleUpgradetypeCancel">取 消</el-button>
                <el-button @click="handleUpgradetypeSubmit" type="primary"
                    v-if="hasPerm(['user.upgrade.description'])">提交更改</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Message, Row } from 'element-ui';
import common from '../../common/common';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            currentPage1: 1,
            total: 0,
            vipstatus: false,
            upgradetype: false,//控制会员升级说明弹层是否显示
            tableData: [],//table data
            pageInfo: {
                page: 1,
                limit: 10
            },//表格翻页信息
            search: {
                name: "",//等级名称
            },
            upgradetcObj: {
                describe: "",//会员升级说明
            },
            searchObj: {
                search: [],
                order: [
                    {
                        "key": "level",
                        "value": "asc"
                    }
                ],
            },
            common_info: {}
        }
    },
    created() {
        //请求全局配置资源(user.level_list,user.is_freeze)
        common.getCommonInfo(this, { keys: ["user.level_status"] }, res => {
            this.common_info = res;
        });
        this.getMemberUserLevelList();
    },
    methods: {
        // 搜索按钮
        handleSearch() {
            this.$set(this, 'searchObj', { ...this.searchObj, ...this.pageInfo });
            this.$set(this.searchObj, 'search', common.handleSearch(this.search, 'search'));
            this.pageInfo.page = 1;
            // 刷新table
            this.getMemberUserLevelList();
        },
        // 手动清除搜索框内容触发事件
        handleInput(value) {
            if (value == "") {
                // 清空
                this.handleClear();
            }
        },

        // 获取会员等级列表
        getMemberUserLevelList() {
            this.$get(this.$apis.memberUserLevel, { ...this.searchObj, ...this.pageInfo }).then(res => {
                if (res.code !== 200) {
                    Message.error(res.message);
                } else {
                    this.$set(this, 'tableData', res.data.list);
                    this.total = res.data.total
                }
            })
        },

        // 清空事件
        handleClear() {
            this.$set(this, "search", common.handleClear(this.search));
            this.handleSearch();
        },
        // pageSize
        handleSizeChange(limit) {
            this.$set(this, "pageInfo", { ...this.pageInfo, limit });
            this.handleSearch();
        },
        // pageNum
        handleCurrentChange(page) {
            this.$set(this, "pageInfo", { ...this.pageInfo, page });
            this.handleSearch();
        },
        // 创建等级按钮
        handleCreateLevel() {
            this.$router.push("/channel/member/member_level_detail")
        },
        // 编辑会员等级
        handleModify(row) {
            this.$router.push({ path: "/channel/member/member_level_detail", query: { id: row.id } })
        },
        // 删除会员等级
        handleDelete(row) {
            this.$confirm(`确定要将该会员等级-【${row.level}】名称-【${row.name}】永久删除吗?`, "提示", {
                type: "warning"
            }).then(() => {
                this.$delete(`${this.$apis.memberLevel}/${row.id}`).then(res => {
                    Message[(() => res.code == 200 ? "success" : "error")()]({
                        message: res.code == 200 ? "会员等级删除成功!" : res.message,
                        offset: 200,
                    })
                    // 刷新列表
                    this.getMemberUserLevelList();
                })
            })
        },
        // 会员升级说明方法
        handleUpgrade() {
            this.upgradetype = true;
        },
        // 会员升级取消
        handleUpgradetypeCancel() {
            this.$refs['pgradetcForm']['resetFields']();
            this.upgradetype = false;
        },
        // 会员升级提交
        handleUpgradetypeSubmit() {
            this.$refs['pgradetcForm']['validate'](valid => {
                if (valid) {
                    this.$put(this.$apis.memberAdvanceUpdate, { "member.advance.describe": this.upgradetcObj.describe }).then(res => {
                        Message[(() => res.code == 200 ? "success" : "error")()]({
                            message: res.code == 200 ? '会员升级说明更新成功!' : res.message,
                            offset: 200,
                            onClose: () => {
                                this.handleUpgradetypeCancel();
                            }
                        })
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
@import url("css/member.css");

.global-search-single {
    margin-bottom: 20px;
}

.tablepage {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/deep/.has-gutter tr th {
    background: #f9f9fc;
    padding: 15px 0;
    box-sizing: border-box;
    text-align: center;
    border-bottom: 1px solid #f2f2f2 !important;
    box-sizing: border-box;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    height: 59px;
}
</style>